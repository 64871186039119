import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  BackendErrorEntity,
  BusFactoryAttributesEntity,
  CreateBusFactoryAttributesBodyDto,
  UpdateBusFactoryAttributesBodyDto
} from '.././types'
import { fetchApiInstance } from '../../fetchApiInstance';
import type { ErrorType, BodyType } from '../../fetchApiInstance';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const apiCreateBusFactoryAttributes = (
    createBusFactoryAttributesBodyDto: MaybeRef<CreateBusFactoryAttributesBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      createBusFactoryAttributesBodyDto = unref(createBusFactoryAttributesBodyDto);
      
      return fetchApiInstance<BusFactoryAttributesEntity>(
      {url: `/bus-factory-attributes`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createBusFactoryAttributesBodyDto
    },
      options);
    }
  


export const getApiCreateBusFactoryAttributesMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiCreateBusFactoryAttributes>>, TError,{data: BodyType<CreateBusFactoryAttributesBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiCreateBusFactoryAttributes>>, TError,{data: BodyType<CreateBusFactoryAttributesBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiCreateBusFactoryAttributes>>, {data: BodyType<CreateBusFactoryAttributesBodyDto>}> = (props) => {
          const {data} = props ?? {};

          return  apiCreateBusFactoryAttributes(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiCreateBusFactoryAttributesMutationResult = NonNullable<Awaited<ReturnType<typeof apiCreateBusFactoryAttributes>>>
    export type ApiCreateBusFactoryAttributesMutationBody = BodyType<CreateBusFactoryAttributesBodyDto>
    export type ApiCreateBusFactoryAttributesMutationError = ErrorType<BackendErrorEntity>

    export const useApiCreateBusFactoryAttributes = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiCreateBusFactoryAttributes>>, TError,{data: BodyType<CreateBusFactoryAttributesBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiCreateBusFactoryAttributes>>,
        TError,
        {data: BodyType<CreateBusFactoryAttributesBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiCreateBusFactoryAttributesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiListBusFactoryAttributes = (
    
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      
      
      return fetchApiInstance<BusFactoryAttributesEntity[]>(
      {url: `/bus-factory-attributes`, method: 'GET'
    },
      options);
    }
  

export const getApiListBusFactoryAttributesQueryKey = () => {
    return ['bus-factory-attributes'] as const;
    }

    
export const getApiListBusFactoryAttributesQueryOptions = <TData = Awaited<ReturnType<typeof apiListBusFactoryAttributes>>, TError = ErrorType<BackendErrorEntity>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiListBusFactoryAttributes>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiListBusFactoryAttributesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiListBusFactoryAttributes>>> = () => apiListBusFactoryAttributes(requestOptions);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiListBusFactoryAttributes>>, TError, TData> 
}

export type ApiListBusFactoryAttributesQueryResult = NonNullable<Awaited<ReturnType<typeof apiListBusFactoryAttributes>>>
export type ApiListBusFactoryAttributesQueryError = ErrorType<BackendErrorEntity>



export function useApiListBusFactoryAttributes<TData = Awaited<ReturnType<typeof apiListBusFactoryAttributes>>, TError = ErrorType<BackendErrorEntity>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiListBusFactoryAttributes>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiListBusFactoryAttributesQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiUpdateBusFactoryAttributes = (
    id: MaybeRef<string | undefined | null>,
    updateBusFactoryAttributesBodyDto: MaybeRef<UpdateBusFactoryAttributesBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      id = unref(id);
updateBusFactoryAttributesBodyDto = unref(updateBusFactoryAttributesBodyDto);
      
      return fetchApiInstance<BusFactoryAttributesEntity>(
      {url: `/bus-factory-attributes/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateBusFactoryAttributesBodyDto
    },
      options);
    }
  


export const getApiUpdateBusFactoryAttributesMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUpdateBusFactoryAttributes>>, TError,{id: string;data: BodyType<UpdateBusFactoryAttributesBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiUpdateBusFactoryAttributes>>, TError,{id: string;data: BodyType<UpdateBusFactoryAttributesBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiUpdateBusFactoryAttributes>>, {id: string;data: BodyType<UpdateBusFactoryAttributesBodyDto>}> = (props) => {
          const {id,data} = props ?? {};

          return  apiUpdateBusFactoryAttributes(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiUpdateBusFactoryAttributesMutationResult = NonNullable<Awaited<ReturnType<typeof apiUpdateBusFactoryAttributes>>>
    export type ApiUpdateBusFactoryAttributesMutationBody = BodyType<UpdateBusFactoryAttributesBodyDto>
    export type ApiUpdateBusFactoryAttributesMutationError = ErrorType<BackendErrorEntity>

    export const useApiUpdateBusFactoryAttributes = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUpdateBusFactoryAttributes>>, TError,{id: string;data: BodyType<UpdateBusFactoryAttributesBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiUpdateBusFactoryAttributes>>,
        TError,
        {id: string;data: BodyType<UpdateBusFactoryAttributesBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiUpdateBusFactoryAttributesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiGetBusFactoryAttributesById = (
    id: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      id = unref(id);
      
      return fetchApiInstance<BusFactoryAttributesEntity>(
      {url: `/bus-factory-attributes/${id}`, method: 'GET'
    },
      options);
    }
  

export const getApiGetBusFactoryAttributesByIdQueryKey = (id: MaybeRef<string | undefined | null>,) => {
    return ['bus-factory-attributes',id] as const;
    }

    
export const getApiGetBusFactoryAttributesByIdQueryOptions = <TData = Awaited<ReturnType<typeof apiGetBusFactoryAttributesById>>, TError = ErrorType<BackendErrorEntity>>(id: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetBusFactoryAttributesById>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiGetBusFactoryAttributesByIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiGetBusFactoryAttributesById>>> = () => apiGetBusFactoryAttributesById(id, requestOptions);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiGetBusFactoryAttributesById>>, TError, TData> 
}

export type ApiGetBusFactoryAttributesByIdQueryResult = NonNullable<Awaited<ReturnType<typeof apiGetBusFactoryAttributesById>>>
export type ApiGetBusFactoryAttributesByIdQueryError = ErrorType<BackendErrorEntity>



export function useApiGetBusFactoryAttributesById<TData = Awaited<ReturnType<typeof apiGetBusFactoryAttributesById>>, TError = ErrorType<BackendErrorEntity>>(
 id: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetBusFactoryAttributesById>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiGetBusFactoryAttributesByIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



